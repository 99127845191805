import { keys } from 'idb-keyval'
import {UseKeysIDB} from "~/composables/keysIDB.js";
import {UseGetData} from "~/composables/getData.js";
import {UseCreatePendingData} from "~/composables/IDB/pending/createPendingData.js";
import {UseUpdateData} from "~/composables/updateData.js";
import {useGeneralApp} from "~/stores/generalApp.js";
import {useUserStore} from "~/stores/user.js";
import {usePendingRemove} from "~/composables/pendingHandle/pendingRemove.js";
import {useGetTicketList} from "~/composables/general/getTicketList.js";

const getPriorityId = (priority) => {
    const generalApp = useGeneralApp()
    return generalApp.priorities.find(inst => inst.name === priority).id
}

const getWorkSpaceID = (arr, workspace) => {
    let resp = arr.find(instance => instance.name === workspace)
    return resp.id
}

const createSubject = (category_arr, place_arr, category, section) => {
    let dangerDetails = category_arr.find(instance => instance.id === category)
    let resp = dangerDetails.title + ' | '
    let sectionName
    place_arr.forEach(instance => {
        if (instance.sections.find(sectionInstance => sectionInstance.id === section) !== undefined) {
            let sectionDetails = instance.sections.find(sectionInstance => sectionInstance.id === section)
            sectionName = sectionDetails.name
        }
    })
    resp = resp + sectionName
    return resp
}

const handleNewTicket = async (data, key) => {
    try {
        const generalApp = useGeneralApp()
        const userStore = useUserStore()
        const config = useRuntimeConfig()
        const route = useRoute()
        const routeChecker = (id) => {
            if (route.params.id.includes('pending')) {
                navigateTo(`/my-tickets/ticket/${id}`)
            }
        }
        let priorityID = getPriorityId(data.data.emergency_type)
        let workspace = getWorkSpaceID(generalApp.places, data.data.danger_zone)
        let subject = createSubject(generalApp.danger_category, generalApp.places, data.data.danger_category, data.data.danger_sub_zone)
        await fetch(`${config.public.apiBase}/tickets/create`, {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + userStore.token,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                subject: subject,
                description: data.data.description,
                workspace_id: workspace,
                section_id: data.data.danger_sub_zone,
                risk_id: data.data.danger_category,
                priority_id: priorityID,
                status_id: 6,
            })
        })
            .then(async (response) => {
                console.log(response.status)
                let res = await response.json()
                if (response.status === 200) {
                    if (data.data.media_attachments.length !== 0) {
                        await UseCreatePendingData(res.Data.id, 'media_data', data.data.media_attachments )
                    }
                    if (data.data.comment !== '') {
                        await UseCreatePendingData(res.Data.id, 'comment', data.data.comment)
                    }
                    await usePendingRemove(key)
                    let pendingExists = await keys(UseKeysIDB().pendingData)
                    if (pendingExists.length === 0) {
                        generalApp.pending = false
                    }
                    generalApp.resetTicketList = true
                    await useGetTicketList()
                    generalApp.resetTicketList = false
                    routeChecker(res.Data.id)
                }
            })
    } catch (err) {
        console.log(err, 'error sending new ticket from pending')
    }
}

const handleNewComment = async (data, key) => {
    try {
        const generalApp = useGeneralApp()
        const userStore = useUserStore()
        const config = useRuntimeConfig()

        await fetch(`${config.public.apiBase}/tickets/${key.split('/')[1]}/add-message`, {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + userStore.token,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                message: data
            })
        }).then(async (response) => {
            if (response.status === 200) {
                await usePendingRemove(key)
                let pendingExists = await keys(UseKeysIDB().pendingData)
                if (pendingExists.length === 0) {
                    generalApp.pending = false
                }

            }
        })
    } catch (err) {
        console.log(err, 'error when send comment')
    }
}

const handleNewMedia = async (attachment, key, counter, length, data) => {
    try {
        const generalApp = useGeneralApp()
        const userStore = useUserStore()
        const config = useRuntimeConfig()
        let attachmentData = new FormData()
        attachmentData.append("file", attachment)
        await fetch(`${config.public.apiBase}/tickets/${key.split('/')[1]}/attachments/upload`, {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + userStore.token,
            },
            body: attachmentData
        }).then(async (response) => {
            let res = await response.json()
            if (response.status === 200) {
                if (counter === length) {
                    await usePendingRemove(key)
                }
                let pendingExists = await keys(UseKeysIDB().pendingData)
                if (pendingExists.length === 0) {
                    generalApp.pending = false
                }
            }
        })
    } catch (err) {
        console.log(err, 'error sending media from pending')
        let newData = {
            operationType: data.operationType,
            in_work: false,
            data: data.data
        }
        await UseUpdateData('pending', key, newData)
    }
}

const handleCommentMedia = async (attachment, key, counter, length, data) => {
    try {
            const generalApp = useGeneralApp()
            const userStore = useUserStore()
            const config = useRuntimeConfig()
            let attachmentData = new FormData()
            attachmentData.append("file", attachment)
            let response = await fetch(`${config.public.apiBase}/messages/${key.split('/')[1]}/attachments`, {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + userStore.token,
                },
                body: attachmentData
            })
            if (response.status === 200) {
                if (counter === length) {
                    await usePendingRemove(key)
                }
                let pendingExists = await keys(UseKeysIDB().pendingData)
                if (pendingExists.length === 0) {
                    generalApp.pending = false
                }
            }
    } catch (err) {
        console.log(err, 'error sending comment media from pending')
        let newData = {
            operationType: data.operationType,
            in_work: false,
            data: data.data
        }
        await UseUpdateData('pending', key, newData)
    }
}

const handleArchive = async (data, key) => {
    console.log(data, key)
    const userStore= useUserStore()
    const generalApp = useGeneralApp()
    const config = useRuntimeConfig()
    await fetch(`${config.public.apiBase}/tickets/${key.split('/')[1]}/${data.data}`, {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + userStore.token,
            "Content-Type": "application/json",
            "Accept": "application/json",
        }
    }).then(async (response) => {
            if (response.status === 200) {
                await usePendingRemove(key)
                let pendingExists = await keys(UseKeysIDB().pendingData)
                if (pendingExists.length === 0) {
                    generalApp.pending = false
                }

            }
    })
}

export const usePendingHandle = async ()     => {
    try {
        const generalApp = useGeneralApp()
        generalApp.pending = true
        let pendingKeys = await keys(UseKeysIDB().pendingData)
        for (let key of pendingKeys) {
            let data = await UseGetData('pending', key)
            if (!data.in_work) {
                let newData = {
                    operationType: data.operationType,
                    in_work: true,
                    data: data.data
                }
                await UseUpdateData('pending', key, newData)
                try {
                    switch (data.operationType) {
                        case 'new_ticket': {
                            await handleNewTicket(data, key)
                            break
                        }
                        case 'media_data': {
                            let counter = 1
                            for (let attachment of data.data ) {
                                await handleNewMedia(attachment, key, counter, data.data.length, data)
                                counter++
                            }
                            break
                        }
                        case 'comment': {
                            await handleNewComment(data.data, key)
                            break
                        }
                        case "comment_media": {
                            let counter = 1
                            for (let attachment of data.data ) {
                                await handleCommentMedia(attachment, key, counter, data.data.length, data)
                                counter++
                            }
                            break
                        }
                        case "archive": {
                            await handleArchive(data, key)
                        }
                    }
                } catch (err) {
                    let newData = {
                        operationType: data.operationType,
                        in_work: false,
                        data: data.data
                    }
                    await UseUpdateData('pending', key, newData)
                }

            }
        }
    } catch (e) {
        const generalApp = useGeneralApp()
        console.log(e, 'error in pendingHandle')
        generalApp.pending = true
        throw e
    }
}