import {usePendingHandle} from "~/composables/pendingHandle/pendingHandle.js";


export const useOnlineCheck = async () => {
    /*window.addEventListener('online', handleConnection);
    window.addEventListener('offline', handleConnection);*/
    if (navigator.onLine) {
        /*console.log('online');*/
        await usePendingHandle();
        /*let online = await isReachable(getServerUrl())
        if (online) {

        } else {
            console.log('no connectivity');
        }*/
    } else {
        console.log("You are off-line");
    }
    /*navigator.connection.onchange = async (e) => {

    };*/
    /*async function handleConnection() {
        if (navigator.onLine) {

        } else {
            console.log('offline');
        }
    }*/

    /*async function isReachable(url) {
        let resp = await fetch(url, { method: 'HEAD', mode: 'no-cors' })
        console.log('response test', resp)
        return resp.status === 200;
        /!*return await fetch(url, { method: 'HEAD', mode: 'no-cors' })
            .then(function(resp) {
                return resp && (resp.ok || resp.type === 'opaque');
            })
            .catch(function(err) {
                console.warn('[conn test failure]:', err);
            });*!/
    }

    function getServerUrl() {
        return window.location.origin;
    }*/
}