import {set} from 'idb-keyval'
import {UseKeysIDB} from "~/composables/keysIDB.js";
import {useUserStore} from "~/stores/user.js";
import {useHandleLogout} from "~/composables/general/handleLogout.js";

export const UseCreateAppData = async (key) => {
    const config = useRuntimeConfig()
    const userStore = useUserStore()
    const notAuthenticated = async (response) => {
        if (response.status === 401) {
            await useHandleLogout()
        }
    }
    const headers = {
        Authorization: "Bearer " + userStore.token,
        "Content-Type": "application/json",
        "Accept": "application/json",
    }
    const options = {
        method: "GET",
        headers: headers
    }
    const errorHandle = async (response, issue) => {
        if (response.status !== 200) {
            console.log(`get ${issue} failed`)
            await notAuthenticated(response)
        } else {
            let res = await response.json();
            set(issue,
                res.Data, UseKeysIDB().appData)
                .then(() => {
                    console.log(`${issue} created`)
                }).catch((e) => {
                console.log(e)
            })
        }
    }
    switch (key) {
        case 'places': {
            await fetch(`${config.public.apiBase}/workspaces/load`, options).then(async (response) => {
                await errorHandle(response, 'places')
            })
            break
        }
        case 'danger_categories': {
            await fetch(`${config.public.apiBase}/directories/risk-categories/load`, options).then(async (response) => {
                await errorHandle(response, 'danger_categories')
            })
            break
        }
        case 'ticket_statuses': {
            await fetch(`${config.public.apiBase}/tickets/statuses`, options).then(async (response) => {
                await errorHandle(response, 'ticket_statuses')
            })
            break
        }
        case 'ticket_priorities': {
            await fetch(`${config.public.apiBase}/tickets/priorities`, options).then(async (response) => {
                await errorHandle(response, 'ticket_priorities')
            })
            break
        }
        case 'isAuthorized': {
            set('isAuthorized',
                false, UseKeysIDB().appData)
                .then(() => {
                    console.log('isAuthorized created')
                }).catch((e) => {
                console.log(e)
            })
            break
        }
    }
}