import {keys} from "idb-keyval";
import {UseKeysIDB} from "~/composables/keysIDB.js";
import {useOnlineCheck} from "~/composables/pendingHandle/onlineCheck.js";
import {useGeneralApp} from "~/stores/generalApp.js";
import {usePendingHandle} from "~/composables/pendingHandle/pendingHandle.js";
import {useResetPendingStatus} from "~/composables/pendingHandle/resetPendingStatus.js";


export const usePendingCheck = async () => {
    try {
        const generalApp = useGeneralApp()
        let pendingKeys = await keys(UseKeysIDB().pendingData)
        if (pendingKeys.length !== 0) {
            generalApp.pending = true
            await useResetPendingStatus()
            await usePendingHandle()
        }
        const checkPending = setInterval(async () => {
            if (generalApp.pending) {
                await useOnlineCheck()
            }
        }, 6000)
    } catch (e) {
        console.log(e, 'error during pending check');
    }

    /*const syncPending = async () => {
        const registration = await navigator.serviceWorker.ready;
        console.log(registration.sync);
        try {
            await registration.sync.register("sync-messages");
        } catch {
            console.log("Background Sync could not be registered!");
        }
        navigator.serviceWorker.ready.then((registration) => {
            registration.sync.getTags().then((tags) => {
                if (tags.includes("sync-messages")) {
                    console.log("Messages sync already requested");
                }
            });
        });
    }
    await syncPending()*/
}